@import (reference) "reset";
@import (reference) "common";

// 表格
.el-table .cell {
  .el-button--mini {
    padding: 6px 10px;
  }
  .el-button+.el-button {
    margin-left: 0;
  }
}
