// display
.df {
  display: flex;
  overflow: hidden;
}

.flexdc {
  .df;
  flex-direction: column;
}

.flexca {
  .df;
  align-items: center;
}

.flexjc {
  .df;
  justify-content: center;
}

.flexcc {
  .flexac;
  justify-content: center;
}

.flexdcc {
  .flexdc;
  align-items: center;
  justify-content: center;
}

.flexcb {
  .flexac;
  justify-content: space-between;
}

.flexac {
  .flexca;
  justify-content: space-around;
}

.flexwp {
  flex-wrap: wrap;
}

.flex1 {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.dib {
  display: inline-block;
  vertical-align: middle;
}

// 滚动
.ovy-a {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 1rem;
}

// 隐藏
.hidden {
  display: none !important;
}

.ov1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ov2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ov1w {
  .ov2;
  -webkit-line-clamp: 1;
}

.ov3 {
  .ov2;
  -webkit-line-clamp: 3;
}

// 图片居中
.img-box {
  position: relative;
  margin: 0 auto;
  font-size: 0;
}

.imgCenter {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  z-index: 1;

  img {
    object-fit: contain;
  }
}

.poaCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.poa100 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bgc {
  background-size: contain;
  background-position: center;
}

.bgs {
  background-size: cover;
  background-position: center;
}

.bga {
  background-size: 100% 100%;
  background-position: center;
}

// 颜色
.c-blue {
  color: #5b80ff;
}

.c-blued {
  color: #6c72ef;
}

.c-red {
  color: #ff573b;
}

.c-green {
  color: #39b924;
}

.c-greenl {
  color: #08c6a2;
}

.c-orange {
  color: #ff7709;
}

.c-orangel {
  color: #ffa053;
}

// 背景色
.box-green {
  background: rgb(0, 198, 80);
}

.box-red {
  background: rgb(251, 79, 66);
}

.box-orange {
  background: rgb(241, 174, 55);
}

.box-gary {
  background: #bdbdbd;
}

.box-curl {
  border: 0;
  background: rgb(189, 60, 51);
}

.bgc-yellow {
  background: #f1ae37;
  border-color: #f1ae37;
}

.bgc-green {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}

.bgc-greenl {
  background-color: #5cb85c;
}

.bgc-orange {
  background-color: #fa853e;
  border-color: #fa853e;
  color: #fff;
}

.bgc-orangee {
  background-color: #f88f71;
  border-color: #f88f71;
}

.bgc-red {
  background-color: #ff573b;
  border-color: #ff573b;
}

.bgc-blue {
  background-color: #29c3ff;
  border-color: #29c3ff;
}

.bgc-blued {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}

.bgc-redd {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.back-hui {
  background-color: #f0f8fa;
  border-color: #f0f8fa;
}

.bgc-bv {
  background-color: #5C6BE8 !important;
  color: #fff !important;
  border-color: #5C6BE8 !important;
}

.bgc-ms {
  background-color: #37C094 !important;
  color: #fff !important;
  border-color: #37C094 !important;
}

.bgc-jg {
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
}

/* 重置 */
.el-select {
  display: block !important;
}

.el-cascader {
  display: block !important;
}

.el-button {
  color: "#fff" !important;
}

.el-button--success:focus,
.el-button--success:hover {
  background-color: #08c6a2;
  color: #fff;
  border-color: #08c6a2;
}

.el-table {
  overflow-y: auto;
}

// 滑块
.el-switch__label--left {
  position: relative;
  left: 60px;
  color: #fff;
  z-index: -1111;
}

.el-switch__label--right {
  position: relative;
  right: 46px;
  color: #fff;
  z-index: -1111;
}

.el-switch__label.is-active {
  z-index: 1111;
  color: #fff;
}

.el-switch__label--left {
  position: relative;
  left: 55px;
  color: #fff;
  z-index: -1111;
}

.el-switch__label--right {
  position: relative;
  right: 60px;
  color: #fff;
  z-index: -1111;
}

.el-switch__label--right.is-active {
  z-index: 1111;
  color: #fff !important;
}

.el-switch__label--left.is-active {
  z-index: 1111;
  color: #9c9c9c !important;
}

.el-switch.is-checked .el-switch__core {
  background-color: rgb(95, 184, 120) !important;
  border-color: rgb(95, 184, 120) !important;
}

.el-switch.is-checked .el-switch__core:after {
  background-color: #fff;
}

.el-switch__label--left.is-active+.el-switch__core {
  border-color: rgb(210, 210, 210) !important;
  background-color: rgb(255, 255, 255) !important;
}

.el-switch__core {
  width: 40px !important;
}

.el-switch__core:after {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 100%;
  transition: all 0.3s;
  width: 16px;
  height: 16px;
  background-color: #fff;
}

.bgc-orange:hover {
  background-color: #f88f71;
  border-color: #f88f71;
  color: #fff;
}

.bgc-bv:hover,
.bgc-bv:focus,
.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  background: #6676FF !important;
  border-color: #6676FF !important;
  color: #fff !important;
}

.bgc-ms:hover,
.bgc-ms:focus,
.bgc-ms:disabled,
.bgc-ms:disabled:hover {
  background: #37C094 !important;
  border-color: #37C094 !important;
  color: #fff !important;
}

.bgc-jg:hover,
.bgc-jg:focus,
.bgc-jg:disabled,
.bgc-jg:disabled:hover {
  background: #2878FF !important;
  border-color: #2878FF !important;
  color: #fff !important;
}

.bor-bv,
.bor-bv:focus,
.bor-bv:hover,
.bor-bv:disabled,
.bor-bv:disabled:hover {
  background: #fff !important;
  border-color: #5C6BE8 !important;
  color: #5C6BE8 !important;
}

.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}

.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}

.el-table .el-table__fixed {
  height: auto !important;
  bottom: 12px;
}

.el-table__fixed-right {
  height: auto !important;
  bottom: 12px;
}