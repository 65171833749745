/* 初始化 */
html,
body,
header,
footer,
nav,
div,
ul,
li,
img,
a,
span,
em,
form,
p,
i,
label,
input,
button,
textarea,
h1,
h2,
h3,
h4,
h5,
section,
article {
  margin: 0;
  border: 0;
  padding: 0;
  font-style: normal;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body {
  min-height: 100vh;
  background-color: #fff;
}

nav,
header,
footer {
  display: block;
  position: relative;
}

html,
body,
button {
  font-family: Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
}

img,
a,
button,
em,
label,
textarea,
span,
i {
  display: inline-block;
}

ul {
  list-style: none;
}

video,
audio {
  outline: none;
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #444;

  &:visited {
    color: #2c3e50;
    text-decoration: none;
  }

  &:active,
  &:hover {
    outline: 0;
    color: #4089fa;
  }
}

.cur-a {
  color: #4089fa !important;
}

label {
  word-wrap: break-word;
  word-break: break-all;
}

input,
button,
textarea {
  border: 0;
  font-size: 1em;
  line-height: 1em;
  font-family: sans-serif;
  text-decoration: none;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input:-webkit-autofill {
  // -webkit-box-shadow: 0 0 0px 1000px white inset;
  // -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

button {
  outline: none;
  cursor: pointer;
}

table {
  width: 100%;
}

/* 进度条 */
/* 滚动条整体 */
::-webkit-scrollbar {
  width: 10px;
  /*滚动条宽度*/
  height: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  /*滑道*/
  background: linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -o-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -ms-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -moz-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -webkit-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  border-radius: 9px;
  /*滑道圆角宽度*/
}

::-webkit-scrollbar-thumb {
  background-color: #93c7f5;
  /*滑动条表面*/
  border-radius: 9px;
  /*滑动条圆角宽度*/
}

::-webkit-scrollbar-corner {
  /*横竖滚动条交角*/
  background-color: transparent;
}


::-moz-scrollbar {
  width: 10px;
  /*滚动条宽度*/
  height: 10px;
}

::-moz-scrollbar-button {
  display: none;
}

::-moz-scrollbar-track {
  background-color: transparent;
}

::-moz-scrollbar-track-piece {
  /*滑道*/
  background: linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -o-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -ms-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -moz-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -webkit-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  border-radius: 9px;
  /*滑道圆角宽度*/
}

::-moz-scrollbar-thumb {
  background-color: #93c7f5;
  /*滑动条表面*/
  border-radius: 9px;
  /*滑动条圆角宽度*/
}

::-moz-scrollbar-corner {
  /*横竖滚动条交角*/
  background-color: transparent;
}

/* 结构 */
body {
  position: relative;
}