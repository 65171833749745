/* 初始化 */
html,
body,
header,
footer,
nav,
div,
ul,
li,
img,
a,
span,
em,
form,
p,
i,
label,
input,
button,
textarea,
h1,
h2,
h3,
h4,
h5,
section,
article {
  margin: 0;
  border: 0;
  padding: 0;
  font-style: normal;
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
html,
body {
  min-height: 100vh;
  background-color: #fff;
}
nav,
header,
footer {
  display: block;
  position: relative;
}
html,
body,
button {
  font-family: Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}
img,
a,
button,
em,
label,
textarea,
span,
i {
  display: inline-block;
}
ul {
  list-style: none;
}
video,
audio {
  outline: none;
  max-width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #444;
}
a:visited {
  color: #2c3e50;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
  color: #4089fa;
}
.cur-a {
  color: #4089fa !important;
}
label {
  word-wrap: break-word;
  word-break: break-all;
}
input,
button,
textarea {
  border: 0;
  font-size: 1em;
  line-height: 1em;
  font-family: sans-serif;
  text-decoration: none;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
}
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}
button {
  outline: none;
  cursor: pointer;
}
table {
  width: 100%;
}
/* 进度条 */
/* 滚动条整体 */
::-webkit-scrollbar {
  width: 10px;
  /*滚动条宽度*/
  height: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  /*滑道*/
  background: linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -o-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -ms-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -moz-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -webkit-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  border-radius: 9px;
  /*滑道圆角宽度*/
}
::-webkit-scrollbar-thumb {
  background-color: #93c7f5;
  /*滑动条表面*/
  border-radius: 9px;
  /*滑动条圆角宽度*/
}
::-webkit-scrollbar-corner {
  /*横竖滚动条交角*/
  background-color: transparent;
}
::-moz-scrollbar {
  width: 10px;
  /*滚动条宽度*/
  height: 10px;
}
::-moz-scrollbar-button {
  display: none;
}
::-moz-scrollbar-track {
  background-color: transparent;
}
::-moz-scrollbar-track-piece {
  /*滑道*/
  background: linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -o-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -ms-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -moz-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  background: -webkit-linear-gradient(to right, #d8d9de, #e6e8ec 100%);
  border-radius: 9px;
  /*滑道圆角宽度*/
}
::-moz-scrollbar-thumb {
  background-color: #93c7f5;
  /*滑动条表面*/
  border-radius: 9px;
  /*滑动条圆角宽度*/
}
::-moz-scrollbar-corner {
  /*横竖滚动条交角*/
  background-color: transparent;
}
/* 结构 */
body {
  position: relative;
}
.df {
  display: flex;
  overflow: hidden;
}
.flexdc {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.flexca {
  display: flex;
  overflow: hidden;
  align-items: center;
}
.flexjc {
  display: flex;
  overflow: hidden;
  justify-content: center;
}
.flexcc {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  justify-content: center;
}
.flexdcc {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flexcb {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  justify-content: space-between;
}
.flexac {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
}
.flexwp {
  flex-wrap: wrap;
}
.flex1 {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}
.dib {
  display: inline-block;
  vertical-align: middle;
}
.ovy-a {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 1rem;
}
.hidden {
  display: none !important;
}
.ov1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ov2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ov1w {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ov3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.img-box {
  position: relative;
  margin: 0 auto;
  font-size: 0;
}
.imgCenter {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  z-index: 1;
}
.imgCenter img {
  object-fit: contain;
}
.poaCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.poa100 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bgc {
  background-size: contain;
  background-position: center;
}
.bgs {
  background-size: cover;
  background-position: center;
}
.bga {
  background-size: 100% 100%;
  background-position: center;
}
.c-blue {
  color: #5b80ff;
}
.c-blued {
  color: #6c72ef;
}
.c-red {
  color: #ff573b;
}
.c-green {
  color: #39b924;
}
.c-greenl {
  color: #08c6a2;
}
.c-orange {
  color: #ff7709;
}
.c-orangel {
  color: #ffa053;
}
.box-green {
  background: #00c650;
}
.box-red {
  background: #fb4f42;
}
.box-orange {
  background: #f1ae37;
}
.box-gary {
  background: #bdbdbd;
}
.box-curl {
  border: 0;
  background: #bd3c33;
}
.bgc-yellow {
  background: #f1ae37;
  border-color: #f1ae37;
}
.bgc-green {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
.bgc-greenl {
  background-color: #5cb85c;
}
.bgc-orange {
  background-color: #fa853e;
  border-color: #fa853e;
  color: #fff;
}
.bgc-orangee {
  background-color: #f88f71;
  border-color: #f88f71;
}
.bgc-red {
  background-color: #ff573b;
  border-color: #ff573b;
}
.bgc-blue {
  background-color: #29c3ff;
  border-color: #29c3ff;
}
.bgc-blued {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-redd {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}
.back-hui {
  background-color: #f0f8fa;
  border-color: #f0f8fa;
}
.bgc-bv {
  background-color: #5C6BE8 !important;
  color: #fff !important;
  border-color: #5C6BE8 !important;
}
.bgc-ms {
  background-color: #37C094 !important;
  color: #fff !important;
  border-color: #37C094 !important;
}
.bgc-jg {
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
}
/* 重置 */
.el-select {
  display: block !important;
}
.el-cascader {
  display: block !important;
}
.el-button {
  color: "#fff" !important;
}
.el-button--success:focus,
.el-button--success:hover {
  background-color: #08c6a2;
  color: #fff;
  border-color: #08c6a2;
}
.el-table {
  overflow-y: auto;
}
.el-switch__label--left {
  position: relative;
  left: 60px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label--right {
  position: relative;
  right: 46px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label.is-active {
  z-index: 1111;
  color: #fff;
}
.el-switch__label--left {
  position: relative;
  left: 55px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label--right {
  position: relative;
  right: 60px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label--right.is-active {
  z-index: 1111;
  color: #fff !important;
}
.el-switch__label--left.is-active {
  z-index: 1111;
  color: #9c9c9c !important;
}
.el-switch.is-checked .el-switch__core {
  background-color: #5fb878 !important;
  border-color: #5fb878 !important;
}
.el-switch.is-checked .el-switch__core:after {
  background-color: #fff;
}
.el-switch__label--left.is-active + .el-switch__core {
  border-color: #d2d2d2 !important;
  background-color: #ffffff !important;
}
.el-switch__core {
  width: 40px !important;
}
.el-switch__core:after {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 100%;
  transition: all 0.3s;
  width: 16px;
  height: 16px;
  background-color: #fff;
}
.bgc-orange:hover {
  background-color: #f88f71;
  border-color: #f88f71;
  color: #fff;
}
.bgc-bv:hover,
.bgc-bv:focus,
.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  background: #6676FF !important;
  border-color: #6676FF !important;
  color: #fff !important;
}
.bgc-ms:hover,
.bgc-ms:focus,
.bgc-ms:disabled,
.bgc-ms:disabled:hover {
  background: #37C094 !important;
  border-color: #37C094 !important;
  color: #fff !important;
}
.bgc-jg:hover,
.bgc-jg:focus,
.bgc-jg:disabled,
.bgc-jg:disabled:hover {
  background: #2878FF !important;
  border-color: #2878FF !important;
  color: #fff !important;
}
.bor-bv,
.bor-bv:focus,
.bor-bv:hover,
.bor-bv:disabled,
.bor-bv:disabled:hover {
  background: #fff !important;
  border-color: #5C6BE8 !important;
  color: #5C6BE8 !important;
}
.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}
.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
.el-table .el-table__fixed {
  height: auto !important;
  bottom: 12px;
}
.el-table__fixed-right {
  height: auto !important;
  bottom: 12px;
}
/* 页面 */
.pageContol {
  font-size: 14px;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  background-color: #f5f5f6;
}
.framePage {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  padding: 0.675rem;
  position: relative;
}
.framePage .framePage-title {
  display: flex;
  justify-content: space-between;
  height: 2.45rem;
  line-height: 2.45rem;
  background-color: #fff;
  padding: 0 1rem;
  margin-bottom: 0.675rem;
  box-shadow: 2px 2px 10px 0 #eee;
}
.framePage .framePage-title em {
  font-size: .8rem;
}
.framePage .framePage-title i {
  color: #444;
  font-size: .8rem;
  font-style: normal;
  margin: 0 0.2rem;
}
.framePage .framePage-title a {
  font-size: .8rem;
}
.framePage .framePage-title .framePage-title-nav a {
  text-decoration: none;
  color: #6b6b6d !important;
}
.framePage .framePage-halfBody {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
}
.framePage .framePage-halfBody .layui-input {
  height: 38px !important;
  line-height: 1.3 !important;
}
.framePage .framePage-body {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
.framePage .framePage-body .framePage-scroll {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 0.675rem 0;
}
.framePage .framePage-body .framePage-scroll > .ovy-a {
  padding: 0 0.675rem;
}
.framePage .pageNum {
  text-align: right;
}
.el-dialog__header {
  background-color: #1b2e4b;
  text-align: center;
  padding: 4px 20px !important;
  position: relative;
}
.el-dialog__header .el-dialog__title {
  color: #fff;
  line-height: 40px;
}
.el-dialog__header .el-dialog__headerbtn {
  top: 50%;
  transform: translateY(-50%);
}
.el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 1.2rem;
}
.el-dialog__header .el-icon-close:before {
  border: 1px solid #fff;
  border-radius: 50%;
}
.operationControl {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  justify-content: space-between;
  overflow: initial;
}
.operationControl .searchboxItem {
  margin-right: 10px;
}
.operationControl .ci-full .el-input input {
  width: 100%;
}
.operationControl > div {
  font-size: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  overflow: initial;
}
.operationControl > div > div > span,
.operationControl > div span > span {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.operationControl button {
  height: 32px;
  font-size: 0.875rem;
  padding: 0 15px;
}
.operationControl .el-button.is-round {
  padding: 0 23px;
}
.operationControl .el-input input {
  width: auto;
}
.operationControl .el-input .el-input__suffix {
  right: 5px;
}
.pageNum {
  text-align: right;
}
.form-title {
  margin: 1rem 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
}
.form-title h2 {
  font-size: 1.325rem;
  padding: 0 1rem;
  font-weight: 400;
  box-sizing: border-box;
  border-left: 4px solid #ccc;
}
.form-title h2 b {
  color: red;
}
.el-table th > .cell {
  padding: 0 10px;
}
.buttonsBox {
  display: flex;
  justify-content: center;
}
.el-table::before {
  border: none;
  height: auto;
}
.el-table__fixed-right::before,
.el-table__fixed::before {
  height: 0;
  width: 0;
}
.el-table /deep/ .el-table__fixed,
.el-table__fixed-right {
  height: calc(100% - 10px) !important;
}
.el-table__fixed-right-patch {
  background-color: #5c6be8;
  top: 1px;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #f2f7fd;
}
.el-upload {
  display: flex !important;
  border: 1px dashed #e2e2e2;
  max-width: 286px;
  height: 138px;
  position: relative;
  color: #8c939d;
}
.el-upload--picture-card i {
  width: 100%;
  height: 100%;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-date-editor {
  display: flex !important;
  width: 100% !important;
}
.el-range-separator {
  display: flex !important;
  align-items: center !important;
}
.framePage-title span .ulbox {
  display: flex;
  justify-content: flex-end;
}
.framePage-title span .ulbox li {
  display: flex;
}
.framePage-title span .ulbox li span {
  margin: 0 0.5rem;
}
.framePage-title span .ulbox li:last-child span {
  display: none;
}
.framePage-title span .ulbox li:hover {
  cursor: pointer;
}
.framePage-title-nav a {
  color: #6b6b6d !important;
}
.tabs {
  color: #4089fa;
}
.active {
  color: #4089fa;
}
.unactive {
  color: #6b6b6d;
}
.searMg {
  margin-bottom: 10px;
}
.searchbox {
  padding: 0;
}
.searchbox > div {
  display: flex;
  align-items: center;
}
.searchbox > div .msgtitle {
  flex: 1;
  align-self: center;
}
.listWrap .item {
  display: flex;
}
.listWrap .btnBox {
  margin-right: 30px;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.listWrap .el-tabs .el-tabs__content .el-tab-pane {
  height: 100%;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 12px;
}
.el-radio .el-radio__inner {
  width: 18px;
  height: 18px;
  min-width: auto;
  border-color: #777;
}
.el-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #5c6be8;
  background-color: #5c6be8;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #5c6be8;
  background-color: #5c6be8;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.custom .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #5c6be8;
  border-color: #5c6be8;
}
.custom .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5c6be8;
}
.seachStyle {
  padding: 0 0.675rem;
  border-bottom: 1px solid #f5f5f6;
  font-size: 14px;
}
.seachStyle .searchList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.675rem;
}
.seachStyle .searchList .listLabel {
  min-width: 65px;
  text-align: right;
  margin-right: 10px;
}
.seachStyle .searchList .listInput {
  width: 100%;
  height: 32px;
}
.seachStyle .listBtn {
  height: 32px;
}
.seachStyle .listBtn .listBtnS {
  height: 100%;
  background-color: #5c6be8;
  color: #fff;
  border-color: #5c6be8;
  padding: 0 19px;
}
.el-tabs .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.formCom .commodity-form {
  width: 80%;
  margin: 0 auto;
}
.formCom .commodity-form .form-item .form-cell {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.formCom .commodity-form .form-item .form-cell span {
  font-size: 12px;
  color: #666666;
}
.formCom .form-item {
  display: flex;
}
.formCom .form-item .el-form-item__content {
  flex: 1;
  margin-left: 0px !important;
  display: flex;
}
.formCom .form-item.el-form-item-long .el-form-item__label {
  align-self: flex-start;
}
.formCom .form-item.form-itemS .el-form-item__label {
  align-self: flex-start;
}
.formCom .form-item-suffix {
  margin-left: 5px;
}
.formCom .form-item-hint .el-form-item__content {
  display: block;
}
.formCom .form-item-hint p {
  line-height: 1.6;
}
.formCom .commodity-textarea .el-textarea__inner {
  height: 200px;
  resize: none;
}
.formCom .el-upload-list--picture-card .el-upload-list__item {
  width: 286px;
  height: 138px;
}
.formCom .fom-title {
  margin-bottom: 2rem;
}
.formCom .form-btn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.formCom .form-btn button {
  width: 10rem;
}
.formCom .item-disabled {
  cursor: not-allowed;
}
.formCom .item-disabled .el-upload.el-upload--text,
.formCom .item-disabled .el-upload-list__item {
  pointer-events: none;
  background-color: #f5f5f5;
}
.el-table .cell .el-button--mini {
  padding: 6px 10px;
}
.el-table .cell .el-button + .el-button {
  margin-left: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
body .el-table th.gutter {
  display: table-cell !important;
}
body .el-table colgroup.gutter {
  display: table-cell !important;
}
.fw-600 {
  font-weight: 600;
}
